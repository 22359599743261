import React, { useEffect, useState, MouseEvent } from 'react';
import { Icon, Text } from '@amzn/storm-ui';
import { Audience } from '../../../models';
import { AudienceSegmentComponentStyled } from './AudienceSegmentComponent.styled';
import { formatAudienceFee } from '../../Table/renderers/FeeRenderer';
import {
  CombinedAudienceClient,
  CustomElementSegment,
} from '@amzn/d16g-pricing-react-utils';
import { isWeblabActive, Weblabs } from '../../../utils/weblabUtil';
import { compareAndPrintForShadowMode } from '../../../utils/pricingUtil';
import { calculateFee, getFeeValue } from '../../../utils/FeeCalculationUtil';
import { COMBINED_AUDIENCES_CATEGORY } from '../../../constants/combined-audiences';
import { useAudienceFeeWithFallback } from '../../../hooks/useAudienceFeeWithFallback';

type AudienceSegmentProps = {
  /**
   * The audience to be shown
   */
  segment: Audience;
  /**
   * List of feeSupplyType(s) that dictate which fee to use from a given audience segment
   */
  feeSupplyType?: string[];
  /**
   * Callback method to remove a segment from an audienceGroup
   */
  onRemove: Function;
};

/**
 * @component
 * Component showing the data of a given audience
 */
const AudienceSegmentComponent = ({
  segment,
  feeSupplyType,
  onRemove,
}: AudienceSegmentProps) => {
  const handleRemoveClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    onRemove();
  };

  const [isLoading, setIsLoading] = useState(true);
  const [cpm, setCpm] = useState<number[]>([]);
  const [currency, setCurrency] = useState('');

  const { observedAudienceFee } = useAudienceFeeWithFallback(
    !!feeSupplyType,
    (segment as unknown) as CustomElementSegment
  );

  useEffect(() => {
    let isCancelled = false;
    let cancelFn: () => void;

    if (segment.category === COMBINED_AUDIENCES_CATEGORY && feeSupplyType) {
      const {
        promise,
        cancel,
      } = CombinedAudienceClient.getInstance().getUnderlyingAudiences(
        segment,
        false
      );

      promise
        .then(underlyingAudiences => {
          if (isCancelled) return;
          const fees = calculateFee(
            underlyingAudiences as Audience[],
            feeSupplyType
          );
          setCpm(fees.feeTotal);
          setCurrency(fees.currencyType);
          setIsLoading(false);
        })
        .catch(() => {});

      cancelFn = cancel;
    } else {
      const { cpm: newCpm, currency: newCurrency } = getFeeValue(
        segment,
        feeSupplyType
      );
      setCpm(newCpm);
      setCurrency(newCurrency);
      setIsLoading(false);
    }

    return () => {
      if (segment.category === COMBINED_AUDIENCES_CATEGORY) cancelFn();
      isCancelled = true;
    };
  }, [segment, feeSupplyType]);

  const legacyAudienceFee = formatAudienceFee(cpm, currency);
  if (isWeblabActive(Weblabs.ADSP_PRICING_UI_REFACTOR))
    setTimeout(
      () =>
        compareAndPrintForShadowMode(
          segment,
          legacyAudienceFee,
          observedAudienceFee
        ),
      1000
    );

  const isUIRefactorWeblabOn = isWeblabActive(
    Weblabs.ADSP_PRICING_UI_REFACTOR,
    'T2'
  );
  const isAudienceFeeLoading = isUIRefactorWeblabOn
    ? observedAudienceFee?.isLoading
    : isLoading;
  const audienceFeeValue = isUIRefactorWeblabOn
    ? observedAudienceFee?.displayValue
    : legacyAudienceFee;

  return (
    <AudienceSegmentComponentStyled.AudienceSegmentContainer>
      <AudienceSegmentComponentStyled.AudienceLeftInfo>
        <AudienceSegmentComponentStyled.AudienceNameText
          fontSize="small"
          title={segment.audienceName}
        >
          {segment.audienceName}
        </AudienceSegmentComponentStyled.AudienceNameText>
        <Text fontSize="mini" textColor="secondary">
          {segment.category}
        </Text>
      </AudienceSegmentComponentStyled.AudienceLeftInfo>
      <AudienceSegmentComponentStyled.AudienceRightInfo>
        {feeSupplyType &&
          (isAudienceFeeLoading ? (
            <Icon type={'spinner'} size="sm" style={{ opacity: '0.5' }} />
          ) : (
            <Text>{audienceFeeValue}</Text>
          ))}
        <AudienceSegmentComponentStyled.CloseButton
          type="times"
          onClick={handleRemoveClick}
        />
      </AudienceSegmentComponentStyled.AudienceRightInfo>
    </AudienceSegmentComponentStyled.AudienceSegmentContainer>
  );
};

export default AudienceSegmentComponent;
