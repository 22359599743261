import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AudienceTargetingGroup } from 'src/MFE/lineItem/model/AudienceTargetingGroup';
import { LineItemProposalPageState } from 'src/model/LineItemPageState';
import { MFEBootstrap } from '@mfe/state-management';
import useFormField from 'src/utils/hooks/useFormField';
import { ComponentIds } from 'src/utils/MFEConstants';
import AudienceEditViewContainer from 'src/MFE/lineItem/edit/AudienceEditViewContainer';
import ApiData from 'src/MFE/lineItem/model/api';
import '@amzn/unified-data-table-components/styles/css/udc-theme.css';
import {
  ConvertFromLegacySegmentStructure,
  ConvertToLegacySegmentStructure,
  LegacyAudienceGroup,
  CustomElementSegment,
} from 'src/utils/AudienceTargetingConverters';
import {
  getImpressionSupplyType,
  ImpressionSupplyTypes,
} from 'src/utils/ImpressionSupplyType';
import PricingHelper from 'src/utils/PricingHelper';
import { isWeblabActive, Weblabs } from 'src/utils/weblabUtil';
import { calculateAudienceFee } from 'src/utils/PricingUtils';
import { supplyFee } from 'src/MFE/lineItem/model/PageStateModels';
import {
  CombinedAudienceClient,
  useMaxAudiencesFees,
} from '@amzn/d16g-pricing-react-utils';
import AudienceTargetingSettings from 'src/model/AudienceTargetingSettings';

interface AudienceTargetingContainerProps {
  pageState: MFEBootstrap.PageState<LineItemProposalPageState>;
  segmentTargeting: AudienceTargetingGroup[];
  advertiserId: string;
  apiData: ApiData;
}

const MILLIVALUE = 100000;

function NonCardAudienceTargetingContainer({
  pageState,
  segmentTargeting,
  apiData,
}: AudienceTargetingContainerProps) {
  useMemo(() => {
    CombinedAudienceClient.initialize(apiData);
  }, [apiData]);

  const [audienceSegments, setAudienceSegments] = useFormField<
    AudienceTargetingGroup[]
  >(
    (lineItemModel) => lineItemModel?.segmentTargeting?.builder,
    ComponentIds.AUDIENCE_TARGETING_NONCARD,
    pageState,
    segmentTargeting
  );

  const [audienceFee, setAudienceFee] = useFormField<supplyFee[]>(
    (lineItemModel) => lineItemModel?.dataFeeView?.supplyFees,
    ComponentIds.AUDIENCE_TARGETING,
    pageState
  );

  const [maxThirdPartyDataFeeView, setMaxThirdPartyDataFeeView] =
    useFormField<number>(
      (lineItemModel) => lineItemModel?.maxThirdPartyDataFeeView?.millivalue,
      ComponentIds.AUDIENCE_TARGETING,
      pageState,
      undefined
    );

  const [isFeesRetrieved, setIsFeesRetrieved] = useState(false);

  const convertedSelectedAudiences = ConvertFromLegacySegmentStructure(
    audienceSegments.value
  );

  const lineItemState =
    pageState?.getCurrentPageState()?.lineItemV1 ||
    pageState?.getCurrentPageState()?.proposalV1;

  // calling pricing hooks for use in display
  // @ts-ignore
  const selectedAudiences =
    // @ts-ignore
    (lineItemState?.segmentTargeting?.builder?.flatMap(
      (group) => group.segments
    ) as CustomElementSegment[]) ?? [];
  const { observedMaxFirstPartyFee, observedMaxThirdPartyFee } =
    useMaxAudiencesFees(selectedAudiences);

  // will not be needed after pricing refactor
  const feeUpdateCountRef = useRef(0);

  const setAudienceSegmentsWithAudienceFee = (
    audiences: AudienceTargetingGroup[]
  ) => {
    if (isWeblabActive(Weblabs.ADSP_PRICING_UI_REFACTOR, 'T2')) {
      setAudienceSegments(audiences);
      const firstPartySupplyFee: [supplyFee] = [
        {
          type: getImpressionSupplyType(lineItemState),
          amount: (Number(observedMaxFirstPartyFee?.value) ?? 0) * MILLIVALUE,
        },
      ];
      setAudienceFee(firstPartySupplyFee);
      setMaxThirdPartyDataFeeView(Number(observedMaxThirdPartyFee?.value) ?? 0);
    } else {
      setAudienceSegments(audiences);

      const currentCallCount = ++feeUpdateCountRef.current;

      calculateAudienceFee(
        audiences as LegacyAudienceGroup[],
        lineItemState
      ).then((fees) => {
        // Check if current call is still the latest before updating state
        if (currentCallCount === feeUpdateCountRef.current) {
          setAudienceFee(fees.audience1PFees.supplyFees);
          setMaxThirdPartyDataFeeView(fees.audience3PFees);
        }
      });
    }
  };

  useEffect(() => {
    if (!isWeblabActive(Weblabs.ADSP_PRICING_UI_REFACTOR, 'T2')) {
      const pricingHelper = PricingHelper.getInstance();

      const updateAudiencePricing = async () => {
        try {
          const pricedSegments: LegacyAudienceGroup[] =
            await pricingHelper.requestPricingForLegacyAudiences(
              segmentTargeting as LegacyAudienceGroup[],
              apiData,
              getImpressionSupplyType(lineItemState)
            );
          setAudienceSegments([]);
          setAudienceSegmentsWithAudienceFee(
            pricedSegments as AudienceTargetingGroup[]
          );
          setIsFeesRetrieved(true);
        } catch (e) {
          console.error('Error requesting prices for legacy audiences');
        }
      };

      if (!pricingHelper.requestCount) updateAudiencePricing();
    }
  }, []);

  useEffect(() => {
    if (audienceSegments.value)
      setAudienceSegmentsWithAudienceFee(
        ConvertToLegacySegmentStructure(
          convertedSelectedAudiences,
          getImpressionSupplyType(lineItemState)
        )
      );
  }, [
    !!audienceSegments.value,
    getImpressionSupplyType(lineItemState),
    observedMaxThirdPartyFee,
    observedMaxFirstPartyFee,
  ]);

  if (
    !isFeesRetrieved &&
    !isWeblabActive(Weblabs.ADSP_PRICING_UI_REFACTOR, 'T2')
  )
    return null;

  const audienceTargetingSettings: AudienceTargetingSettings = {
    useUasSelection: false,
    useFees: true,
  };

  return (
    <AudienceEditViewContainer
      pageState={pageState}
      apiData={apiData}
      setAudienceSegments={setAudienceSegmentsWithAudienceFee}
      audienceSegments={audienceSegments.value}
      isEntityAllowListed={false}
      isAudienceIncompatible={false}
      audienceTargetingSettings={audienceTargetingSettings}
    />
  );
}

export default NonCardAudienceTargetingContainer;
