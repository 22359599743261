// @ts-nocheck

import axios, { AxiosError, AxiosInstance } from 'axios';
import { ApiData, AudienceSegmentsResponse, Filter } from '../models';
import { AudiencePriceRequesterV2 } from '@amzn/d16g-pricing-shared-client-library';
import { MediaTypes } from '@amzn/d16g-pricing-shared-client-library/src/constants';

import { rehydratePrices } from '../utils/pricingUtil';
import { PricingContext } from '@amzn/d16g-pricing-shared-client-library/src';
import { isWeblabActive, Weblabs } from '../utils/weblabUtil';

export interface FilterResponse {
  category: string;
  audienceCount: number;
}

export const AD_TYPE = 'DSP';
export const RETRY_INTERVAL = 3000;
export const REQUEST_TIMEOUT = 30000;

export const constructHeaders = (apiData: ApiData) => {
  const { clientId, csrfToken, entityId, marketplaceId } = apiData;
  return {
    'amazon-advertising-api-clientid': clientId,
    'amazon-advertising-api-csrf-data': clientId,
    'amazon-advertising-api-csrf-token': csrfToken,
    'amazon-advertising-api-advertiserid': entityId,
    'amazon-advertising-api-marketplaceid': marketplaceId,
    'amazon-advertising-accountId': entityId,
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': '*',
    'x-amz-request-csrf-token': true,
    accept: '*/*',
  };
};

/**
 * The client holds the front-end calls to the respective API endpoints
 * @param {ApiData}  apiData
 */
export class AudienceDataClient {
  private readonly client: AxiosInstance;
  private readonly priceRequester: AudiencePriceRequesterV2;

  private static readonly MAX_RESULTS: number = 250;

  public advertiserId: string;
  public getAudienceSegmentsOverride:
    | ((
        filters: Filter[],
        audiencesNeeded: number,
        abortController: AbortController,
        nextToken?: string
      ) => AudienceSegmentsResponse)
    | undefined;
  public headers: {};
  public endpoint: string;
  public defaultFilters: Filter[];
  public apiData: ApiData;
  public observeAudiencesForPricing: () => void;
  public usingFeeColumn: boolean;

  public constructor(
    apiData: ApiData,
    observeAudiencesForPricing?: () => void,
    usingFeeColumn: boolean = true
  ) {
    const {
      advertiserId,
      baseURL,
      getAudienceSegmentsOverride,
      endpoint = 'audiences/list',
      defaultFilters,
      headers,
    } = apiData;
    // pricing requester expects "baseUrl" attribute not "baseURL"
    apiData.baseUrl = apiData.baseURL;
    this.apiData = apiData;
    this.advertiserId = advertiserId;
    this.getAudienceSegmentsOverride = getAudienceSegmentsOverride;
    this.headers = headers || constructHeaders(apiData);
    this.endpoint = endpoint;
    this.defaultFilters = defaultFilters || [];
    this.priceRequester = new AudiencePriceRequesterV2(apiData);
    this.client = axios.create({
      baseURL,
      withCredentials: true,
      timeout: REQUEST_TIMEOUT,
    });
    this.observeAudiencesForPricing = observeAudiencesForPricing || null;
    this.usingFeeColumn = usingFeeColumn;
  }

  /**
   * API endpoint to request audiences to populate the table.
   * @param {Filter[]} filter - list of filters to pass into the API endpoint, such as search results or a selected category
   * @param {number} audiencesNeeded the number of audiences being requested
   * @param {AbortController} abortController - callback function to stop the API call if needed
   * @param {string} nextToken - response from the API with a token to know to request the next page of data
   * @returns {AudienceSegmentsResponse} audienceSegmentsResponse
   */
  public async getAudienceSegments(
    filters: Filter[],
    audiencesNeeded: number,
    abortController: AbortController,
    nextToken?: string
  ): Promise<AudienceSegmentsResponse> {
    try {
      // on rejected call, use retry logic:
      this.client?.interceptors?.response?.use(undefined, err => {
        const { config, response } = err;
        if (!config || !config.retries) return Promise.reject(err);
        // retry on status 429
        if (!(response?.status == 429)) return Promise.reject(err);

        config.retries -= 1;
        const delayRetryRequest = new Promise(resolve => {
          setTimeout(() => {
            console.error('retry the request', config.url);
            resolve();
          }, RETRY_INTERVAL);
        });
        return delayRetryRequest.then(() => this.client(config));
      });

      const response: any = await this.client
        .post<AudienceSegmentsResponse>(
          this.endpoint,
          { adType: AD_TYPE, filters: filters || [] },
          {
            retries: 3,
            headers: this.headers,
            params: {
              advertiserId: this.advertiserId,
              nextToken,
              maxResults: Math.min(audiencesNeeded, 250),
              canTarget: true,
            },
            signal: abortController.signal,
          }
        )
        .then(resp => resp.data);

      if (
        isWeblabActive(Weblabs.ADSP_PRICING_UI_REFACTOR, 'T2') &&
        this.observeAudiencesForPricing
      )
        this.observeAudiencesForPricing(response.audiences);

      const pricingContext: PricingContext = {
        mediaTypes: [
          MediaTypes.WEB_DISPLAY,
          MediaTypes.STREAMING_AUDIO,
          MediaTypes.VIDEO,
          MediaTypes.OTT_VIDEO,
        ],
        advertiserId: this.apiData.advertiserId,
        orderId: this.apiData.orderId,
        entityId: this.apiData.entityId,
        country: this.apiData.country,
        lineItemId: this.apiData.lineItemId,
        audiences: response.audiences,
      };

      if (!this.usingFeeColumn) return response;

      const pengPrices = await this.priceRequester.getPricingResponse(
        pricingContext
      );
      return rehydratePrices(response, pengPrices);
    } catch (e) {
      const error = e as AxiosError<any>;
      if (error && error.toJSON) console.error(error.toJSON());
      throw error;
    }
  }

  /**
   * Requests the list of available filters for the filter tree.
   * @param {string} categoryPath - If no category is passed, returns the high-level filters, passing in a categoryPath returns the sub-filters for that categoryPath
   */
  public async getFilterTaxonomy(
    categoryPath: string = ''
  ): Promise<FilterResponse[]> {
    try {
      // on rejected call, use retry logic:
      this.client?.interceptors?.response?.use(undefined, err => {
        const { config, response } = err;
        if (!config || !config.retries) return Promise.reject(err);
        // retry on status 429
        if (!(response?.status == 429)) return Promise.reject(err);

        config.retries -= 1;
        const delayRetryRequest = new Promise(resolve => {
          setTimeout(() => {
            console.error('retry the request', config.url);
            resolve();
          }, RETRY_INTERVAL);
        });
        return delayRetryRequest.then(() => this.client(config));
      });

      const response: any = await this.client
        .post<FilterResponse[]>(
          'audiences/taxonomy/list',
          { adType: AD_TYPE, categoryPath: categoryPath ? [categoryPath] : [] },
          {
            headers: this.headers,
            retries: 3,
            params: {
              advertiserId: this.advertiserId,
            },
          }
        )
        .then(resp => resp.data);
      return response.categories;
    } catch (e) {
      const error = e as AxiosError<any>;
      if (error && error.toJSON) console.error(error.toJSON());
      throw error;
    }
  }

  /**
   * API endpoint to request audiences to populate the table.
   * @param {Array<string>} audienceId - list of audiences to fetch
   * @param {string} nextToken - response from the API with a token to know to request the next page of data
   * @returns {AudienceSegmentsResponse} audienceSegmentsResponse
   */
  public async getAudienceById(
    audienceId: Array<string>,
    nextToken?: string
  ): Promise<AudienceSegmentsResponse> {
    try {
      // on rejected call, use retry logic:
      this.client?.interceptors?.response?.use(undefined, err => {
        const { config, response } = err;
        if (!config || !config.retries) return Promise.reject(err);
        // retry on status 429
        if (!(response?.status == 429)) return Promise.reject(err);

        config.retries -= 1;
        const delayRetryRequest = new Promise(resolve => {
          setTimeout(() => {
            console.error(`Fetching audience for audienceId ${audienceId}`);
            resolve();
          }, RETRY_INTERVAL);
        });
        return delayRetryRequest.then(() => this.client(config));
      });

      const response: any = await this.client
        .post<AudienceSegmentsResponse>(
          this.endpoint,
          {
            adType: AD_TYPE,
            filters: [{ field: 'audienceId', values: audienceId }],
          },
          {
            retries: 3,
            headers: this.headers,
            params: {
              advertiserId: this.advertiserId,
              nextToken,
              maxResults: AudienceDataClient.MAX_RESULTS,
              canTarget: true,
            },
          }
        )
        .then(resp => resp.data);

      const pricingContext: PricingContext = {
        mediaTypes: [
          MediaTypes.WEB_DISPLAY,
          MediaTypes.STREAMING_AUDIO,
          MediaTypes.VIDEO,
          MediaTypes.OTT_VIDEO,
        ],
        advertiserId: this.apiData.advertiserId,
        orderId: this.apiData.orderId,
        entityId: this.apiData.entityId,
        country: this.apiData.country,
        lineItemId: this.apiData.lineItemId,
        audiences: response.audiences,
      };

      if (!this.usingFeeColumn) return response;

      const pengPrices = await this.priceRequester.getPricingResponse(
        pricingContext
      );

      return rehydratePrices(response, pengPrices);
    } catch (e) {
      const error = e as AxiosError<any>;
      if (error && error.toJSON) console.error(error.toJSON());
      throw error;
    }
  }

  /**
   * getter method for the passed in abortController from apiData
   */
  public getAbortController(): AbortController {
    // eslint-disable-line class-methods-use-this
    return new AbortController();
  }
}
