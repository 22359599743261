import {
  CustomElementSegment,
  EMPTY_DISPLAY_FEE,
  useAudienceFee,
} from '@amzn/d16g-pricing-react-utils';

export const useAudienceFeeWithFallback = (
  usingFeeColumn: boolean,
  audience?: CustomElementSegment
) => {
  try {
    return useAudienceFee(audience);
  } catch (error) {
    if (!usingFeeColumn)
      // Not using fee column, can ignore error
      return {
        observeAudiencesForPricing: () => {},
        observedAudienceFee: EMPTY_DISPLAY_FEE,
      };
    throw error;
  }
};
