import * as REGIONS from 'src/constants/regions';
import * as ENVIRONMENTS from 'src/constants/environments';

export const LOGGER_ENDPOINT = 'v1/log';

export const LOGGER_ENDPOINT_MAP = {
  [REGIONS.REGION_NA]: {
    [ENVIRONMENTS.ENV_BETA]: `https://dn2eeu4ola.execute-api.us-west-2.amazonaws.com/prod/${LOGGER_ENDPOINT}`,
    [ENVIRONMENTS.ENV_GAMMA]: `https://yw7rollb54.execute-api.us-east-1.amazonaws.com/prod/${LOGGER_ENDPOINT}`,
    [ENVIRONMENTS.ENV_PROD]: `https://v40gi8asn0.execute-api.us-east-1.amazonaws.com/prod/${LOGGER_ENDPOINT}`,
    [ENVIRONMENTS.ENV_DEV]: undefined,
  },
  [REGIONS.REGION_JP]: {
    [ENVIRONMENTS.ENV_GAMMA]: `https://z8ac1acc3h.execute-api.us-west-2.amazonaws.com/prod/${LOGGER_ENDPOINT}`,
    [ENVIRONMENTS.ENV_PROD]: `https://ddk6jkeu1m.execute-api.us-west-2.amazonaws.com/prod/${LOGGER_ENDPOINT}`,
    [ENVIRONMENTS.ENV_DEV]: undefined,
  },
  [REGIONS.REGION_EU]: {
    [ENVIRONMENTS.ENV_GAMMA]: `https://68foa3dk4i.execute-api.eu-west-1.amazonaws.com/prod/${LOGGER_ENDPOINT}`,
    [ENVIRONMENTS.ENV_PROD]: `https://zxlgiqodnb.execute-api.eu-west-1.amazonaws.com/prod/${LOGGER_ENDPOINT}`,
    [ENVIRONMENTS.ENV_DEV]: undefined,
  },
};
