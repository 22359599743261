export enum TranslatorString { // eslint-disable-line no-shadow
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_REPLACE_AUDIENCE = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_REPLACE_AUDIENCE',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_REPLACE_AUDIENCE_CONFIRM = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_REPLACE_AUDIENCE_CONFIRM',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_CANCEL = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_CANCEL',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SAVE_CHANGES = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SAVE_CHANGES',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SAVE_CONFIRMATION = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SAVE_CONFIRMATION',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_CLEAR_AUDIENCE_CONFIRM = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_CLEAR_AUDIENCE_CONFIRM',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_REVIEW_AND_UPDATE = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_REVIEW_AND_UPDATE',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_ADD_AUDIENCES = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_ADD_AUDIENCES',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AND = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AND',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_OR = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_OR',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_NOT = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_NOT',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_GROUP = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_GROUP',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE_ATS_ENABLED_TEXT = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE_ATS_ENABLED_TEXT',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE_ATS_ENABLED_EXTERNAL_USER_TEXT = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE_ATS_ENABLED_EXTERNAL_USER_TEXT',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_EMPTY_DROPDOWN_TEXT = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_EMPTY_DROPDOWN_TEXT',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_INCLUDE_LABEL = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_INCLUDE_LABEL',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_INCLUDE_DESC = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_INCLUDE_DESC',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_EXCLUDE_LABEL = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_EXCLUDE_LABEL',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_EXCLUDE_DESC = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_EXCLUDE_DESC',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_REPLACE_LABEL = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_REPLACE_LABEL',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_REMOVE_LABEL = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_REMOVE_LABEL',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_REMOVE_DESC = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_REMOVE_DESC',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE_CANNOT_BE_SELECTED = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE_CANNOT_BE_SELECTED',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AMAZON_DEMOGRAPHIC_AUDIENCE_CANNOT_BE_SELECTED = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AMAZON_DEMOGRAPHIC_AUDIENCE_CANNOT_BE_SELECTED',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AMAZON_IN_MARKET_LIFESTYLE_AUDIENCE_CANNOT_BE_SELECTED = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AMAZON_IN_MARKET_LIFESTYLE_AUDIENCE_CANNOT_BE_SELECTED',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_TARGETING = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_TARGETING',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_SELECTED = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_SELECTED',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_EXCLUDING = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_EXCLUDING',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_APPLIED = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_APPLIED',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_NONE = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_NONE',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_TEXT = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_TEXT',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_UPDATE = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_UPDATE',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_DISMISS = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_DISMISS',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_UPDATE_SUCCESS = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_UPDATE_SUCCESS',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_AUDIENCE_TARGETING_MATCH_TYPE_CHECKBOX_TEXT = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_AUDIENCE_TARGETING_MATCH_TYPE_CHECKBOX_TEXT',
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_AUDIENCE_TARGETING_MATCH_TYPE_CHECKBOX_LEARN_ABOUT_TEXT = 'ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_AUDIENCE_TARGETING_MATCH_TYPE_CHECKBOX_LEARN_ABOUT_TEXT',
  ADSP_D16G_AUDIENCE_PICKER_REMARKETING_TACTIC_IDENTIFIER = 'ADSP_D16G_AUDIENCE_PICKER_REMARKETING_TACTIC_IDENTIFIER',
  ADSP_D16G_AUDIENCE_PICKER_RETENTION_TACTIC_IDENTIFIER = 'ADSP_D16G_AUDIENCE_PICKER_RETENTION_TACTIC_IDENTIFIER',
  ADSP_D16G_AUDIENCE_PICKER_PROSPECTING_TACTIC_IDENTIFIER = 'ADSP_D16G_AUDIENCE_PICKER_PROSPECTING_TACTIC_IDENTIFIER',
  ADSP_D16G_AUDIENCE_PICKER_PROSPECTING_BRAND_PLUS_TACTIC_IDENTIFIER = 'ADSP_D16G_AUDIENCE_PICKER_PROSPECTING_BRAND_PLUS_TACTIC_IDENTIFIER',
  ADSP_D16G_AUDIENCE_PICKER_TACTIC_CATEGORY_TEXT = 'ADSP_D16G_AUDIENCE_PICKER_TACTIC_CATEGORY_TEXT',
}

type TranslatorStrings = keyof typeof TranslatorString;

interface TranslationPartialConfig {
  fallback: string;
}

interface TranslationConfig extends TranslationPartialConfig {
  stringId: string;
}

const TranslatorConfigs: {
  [key in TranslatorStrings]: TranslationPartialConfig;
} = {
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_REPLACE_AUDIENCE: {
    fallback: 'Replace Audience',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_REPLACE_AUDIENCE_CONFIRM: {
    fallback:
      'Once new audience is saved you wont be able to see the original audience.',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_CANCEL: {
    fallback: 'Cancel',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SAVE_CHANGES: {
    fallback: 'Save changes',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SAVE_CONFIRMATION: {
    fallback: 'Do you want to save?',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_CLEAR_AUDIENCE_CONFIRM: {
    fallback:
      "You haven't created new audiences. The original audiences will be deleted if you save changes.",
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_REVIEW_AND_UPDATE: {
    fallback: 'Please review and update this audience',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_ADD_AUDIENCES: {
    fallback: 'Add audiences',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AND: {
    fallback: 'and',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_OR: {
    fallback: 'or',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_NOT: {
    fallback: 'not',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_GROUP: {
    fallback: 'Group 1',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE: {
    fallback: 'Audience',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE_ATS_ENABLED_TEXT: {
    fallback:
      "You can't use audience. Remove advanced targeting string to use audience.",
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE_ATS_ENABLED_EXTERNAL_USER_TEXT:
    {
      fallback:
        "You can't use audience. Please contact your Amazon representative to make changes.",
    },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_EMPTY_DROPDOWN_TEXT: {
    fallback: 'Choose how you want to change your audiences',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_INCLUDE_LABEL: {
    fallback: 'Include Audiences',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_INCLUDE_DESC: {
    fallback: 'Add audiences to include in a campaign.',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_EXCLUDE_LABEL: {
    fallback: 'Exclude Audiences',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_EXCLUDE_DESC: {
    fallback: 'Add audiences to exclude from a campaign.',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_REPLACE_LABEL: {
    fallback: 'Replace Audiences',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_REMOVE_LABEL: {
    fallback: 'Delete Audiences',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_REMOVE_DESC: {
    fallback:
      'Add audiences to delete from a campaign. They will be deleted regardless of whether they are included or excluded from each line item.',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE_CANNOT_BE_SELECTED: {
    fallback: 'This audience cannot be selected for this line item type.',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AMAZON_DEMOGRAPHIC_AUDIENCE_CANNOT_BE_SELECTED:
    {
      fallback:
        "This audience can't be selected for this line item because the deal doesn't allow Amazon demographic (age or gender) targeting.",
    },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AMAZON_IN_MARKET_LIFESTYLE_AUDIENCE_CANNOT_BE_SELECTED:
    {
      fallback:
        "This audience can't be selected for this line item because the deal doesn't allow Amazon in-market or lifestyle targeting.",
    },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_TARGETING: {
    fallback: 'Target:',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_SELECTED: {
    fallback: 'Selected:',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_EXCLUDING: {
    fallback: 'Exclude:',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_APPLIED: {
    fallback: 'Applied:',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_NONE: {
    fallback: 'None',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_TEXT: {
    fallback:
      'Based on your campaign goals and audience selection, adding Twitch inventory can help you reach more of your target audience.',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_UPDATE: {
    fallback: 'Update Inventory',
  },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_DISMISS:
    {
      fallback: 'Dismiss',
    },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_UPDATE_SUCCESS:
    {
      fallback: 'Twitch added in Inventory',
    },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_AUDIENCE_TARGETING_MATCH_TYPE_CHECKBOX_TEXT:
    {
      fallback: 'Reach similar audiences.',
    },
  ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_AUDIENCE_TARGETING_MATCH_TYPE_CHECKBOX_LEARN_ABOUT_TEXT:
    {
      fallback: 'Learn more',
    },
  ADSP_D16G_AUDIENCE_PICKER_REMARKETING_TACTIC_IDENTIFIER: {
    fallback: 'Performance+ Remarketing',
  },
  ADSP_D16G_AUDIENCE_PICKER_RETENTION_TACTIC_IDENTIFIER: {
    fallback: 'Performance+ Retention',
  },
  ADSP_D16G_AUDIENCE_PICKER_PROSPECTING_TACTIC_IDENTIFIER: {
    fallback: 'Performance+ Prospecting',
  },
  ADSP_D16G_AUDIENCE_PICKER_PROSPECTING_BRAND_PLUS_TACTIC_IDENTIFIER: {
    fallback: 'Brand+ Upper Funnel Prospecting',
  },
  ADSP_D16G_AUDIENCE_PICKER_TACTIC_CATEGORY_TEXT: {
    fallback: 'Amazon-curated',
  },
} as const;

export function getTranslationConfig(
  translatorString: TranslatorString
): TranslationConfig {
  return {
    stringId: translatorString,
    ...TranslatorConfigs[translatorString],
  };
}

export function findTranslatorString(
  stringId?: string
): TranslatorString | undefined {
  if (!stringId) return undefined;

  if (!Object.keys(TranslatorConfigs).includes(stringId)) return undefined;

  // By definition of the TranslatorConfigs map, which contains all TranslatorString enum values exclusively,
  // if the TranslatorConfigs map has the stringId as a property, the stringId is a TranslatorString.
  return stringId as TranslatorString;
}
