import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CurrencyCellRenderer,
  HeaderPanelContainer,
  PanelGroup,
  QuickFilter,
  TextCellRenderer,
  UnifiedDataTable,
  ValidationStatusEnum,
  ValidationTextResult,
} from '@amzn/unified-data-table-components/core';
import { Pill, ThemeProvider } from '@amzn/storm-ui';

import { FilterPillContainer, FooterContainer } from './TableComponent.styled';

import { DEFAULT_PAGE_SIZE, Pagination } from './Pagination';
import {
  GridApi,
  GridOptions,
  GridReadyEvent,
  ColumnApi,
  ModuleRegistry,
} from '@ag-grid-community/core';
import '@amzn/unified-data-table-components/styles/css/udc-theme.css';
import {
  FILTER_DROPDOWN_VIRT_COL_ID,
  getColumnDefs,
  TEXT_SEARCH_VIRT_COL_ID,
} from './column/columnDefinition';
import { AudienceDataClient } from '../../api';
import { ApiData } from 'models';
import { FilterDropdown } from './filter/FilterDropdown';
import './styleOverride.css';
import { AudienceCategory, FilterNames } from '../../models/FilterSet';
import { SearchDataSource } from './datasource/SearchDataSource';
import { AudienceSearchFilter } from './AudienceCategoryFilter';
import { FilterTree } from './filter/FilterTree/FilterTree';
import { TranslationContext, capitalize } from '../../state/translationContext';
import {
  HEADER_NAME,
  HEADER_CATEGORY,
  HEADER_FEE,
  HEADER_DAILY_IMPRESSIONS,
  HEADER_DAILY_REACH,
  SEARCH_AUDIENCES,
  INPUT_CONTAINS_CODE_ERROR_MESSAGE,
  INPUT_TOO_LONG_ERROR_MESSAGE,
} from '../../constants/translations';
import { NoRowsOverlay } from './NoRowsOverlay';
import { useVisibility } from '../../hooks/useVisibility';
import { useResizeObservable } from '../../hooks/useResizeObservable';
import {
  FULL_TABLE_HEIGHT,
  HALF_TABLE_HEIGHT,
  MARGIN_BETWEEN_TABLES,
} from '../../constants/suggestion';
import { TaktLoggingContext } from '../../state';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

type TableComponentProps = {
  /**
   * parameters for the API to make a request for audiences to populate the table.
   */
  apiData: ApiData;
  /**
   * Whether to show the category dropdown filter in the header of the table.
   */
  useCategoryDropdownFilter?: boolean;
  /**
   * List of categories to be shown in the filters dropdown(if `useCategoryDropdownFilter` is `true`, and this is not provided, the filterTaxonomy will be used instead).
   */
  allowedFilterCategories?: AudienceCategory[];
  /**
   * Whether to show the "All" filter if there is a list of allowedFilterCategories passed in.
   */
  removeAllFilter?: string;
  /**
   * Instance of the client that's used to make API calls
   */
  audienceDataClient: AudienceDataClient;
  /**
   * List of feeSupplyType(s) that dictate which fee to use from a given audience segment
   */
  feeSupplyType?: string[];
  /**
   * Whether to show the "legacy" logic version of the picker, or the "include/exclude" logic version of the picker.
   */
  useLegacy?: boolean;
  /**
   * Callback method to get the height of the table and dynamically update it.
   */
  getTableHeight?: Function;
  /**
   * Passed in method that accepts an audience and if that audience meets some criterion to be disabled, returns the error message that is to be shown as a tooltip.
   */
  disableRowsCallback?: (data: any) => string;
  /**
   * Method to force the table column data to update on some given criterion.
   */
  triggerColumnDefs?: any;
  /**
   * Specified language code to trigger the TranslationContext and provide strings in the requested locale.
   */
  locale?: string;
  /**
   * The selected mutation type for the bulk edit use-case.
   */
  bulkEditMutationType?: string;
  setSelectionHeight: (height: number) => void;
  showSuggestions?: boolean;
  isInclusionDisabled?: boolean;
  inclusionDisabledReasonCode?: string;
  highLevelFilters?: string[];
  showForecast?: boolean;
};

const DEBOUNCE_TIME = 500;

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ServerSideRowModelModule,
]);

/**
 * Component to show the table that contains the list of audiences, with options for showing fees, category filter, search filter, and pagination.
 */
export const TableComponent = (props: TableComponentProps) => {
  const takt = useContext(TaktLoggingContext);
  const getTranslation = useContext(TranslationContext);
  const headerLabels = {
    name: capitalize(getTranslation(HEADER_NAME)),
    category: capitalize(getTranslation(HEADER_CATEGORY)),
    fee: capitalize(getTranslation(HEADER_FEE)),
    dailyImpressions: capitalize(getTranslation(HEADER_DAILY_IMPRESSIONS)),
    dailyReach: capitalize(getTranslation(HEADER_DAILY_REACH)),
  };
  const columnDefs = getColumnDefs(headerLabels, props);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentlyLoading, setCurrentlyLoading] = useState<boolean>(false);
  const [loadedFilter, setLoadedFilter] = useState<string>('');
  const [tableHeight, setTableHeight] = useState<string>(
    `${FULL_TABLE_HEIGHT}px`
  );
  const [succeeded, setSucceeded] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const isTableVisible = useVisibility(ref);

  const gridOptions: GridOptions = {
    rowModelType: 'serverSide',
    serverSideStoreType: 'partial',
    columnDefs,
    components: {
      TextCellRenderer,
      CurrencyCellRenderer,
    },
    frameworkComponents: {
      audienceCategoryFilter: AudienceSearchFilter,
      noRowsOverlay: NoRowsOverlay,
    },
  };

  const tHeight = props.getTableHeight?.(props.showSuggestions);
  useEffect(() => {
    if (tHeight) setTableHeight(tHeight);
  }, [tHeight]);

  const callback = useCallback(
    (mutationList: any) => {
      if (!props.getTableHeight) {
        const suggestionsHeader =
          mutationList[0].target.nextSibling.querySelector(
            '#suggestions-header'
          )?.offsetHeight || 0;

        const getElementHeight = (elementId: string) =>
          mutationList[0].target.querySelector(`#${elementId}`)?.offsetHeight ||
          0;

        // get header height by the sum of suggestion title div, table header, filter and margin (7px).
        // when suggestions are displayed - suggestion title div, Margin (7px) gets divided between discovery and suggestions table hence diving by 2
        const header =
          (props.showSuggestions
            ? suggestionsHeader / 2 + MARGIN_BETWEEN_TABLES / 2
            : suggestionsHeader + 7) +
          getElementHeight('header-panel') +
          getElementHeight('filter-panel');

        const footer = getElementHeight('footer-panel');

        const computedTableHeight = `${(props.showSuggestions
          ? HALF_TABLE_HEIGHT
          : FULL_TABLE_HEIGHT) -
          header -
          footer}px`;

        if (tableHeight !== computedTableHeight)
          setTableHeight(computedTableHeight);
      }
    },
    [props.showSuggestions, props.getTableHeight, tableHeight]
  );

  useResizeObservable<HTMLDivElement | null>(ref.current, callback);

  useEffect(() => {
    if (isTableVisible && gridApi?.sizeColumnsToFit)
      columnApi?.autoSizeAllColumns();
  }, [isTableVisible]);

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      setGridApi(event.api);
      setColumnApi(event.columnApi);
    },
    [setGridApi, setColumnApi]
  );

  const searchDataSource = useMemo(
    () =>
      new SearchDataSource({
        audienceDataClient: props.audienceDataClient,
        setCurrentlyLoading,
        taktContext: takt,
        pageNumber,
        pageSize,
        bulkEditMutationType: props.bulkEditMutationType,
      }),
    [props.audienceDataClient]
  );

  const filterTable = (filter: string) => {
    if (!gridApi) return;
    gridApi.getFilterInstance(FILTER_DROPDOWN_VIRT_COL_ID, filterInstance => {
      // @ts-ignore: Object is possibly 'null'.
      filterInstance.setModel(
        filter
          ? {
              field: FilterNames.category,
              values: [filter],
            }
          : undefined
      );
      gridApi.onFilterChanged();
    });
  };

  useEffect(() => {
    gridApi?.setColumnDefs(columnDefs);
    gridApi?.sizeColumnsToFit();
  }, [props.triggerColumnDefs]);

  useEffect(() => {
    if (!gridApi) return;
    gridApi.setServerSideDatasource(searchDataSource);
    setSucceeded(searchDataSource.succeeded);
    if (props.removeAllFilter) filterTable(props.removeAllFilter);
  }, [gridApi, searchDataSource, searchDataSource.succeeded]);

  const clearFilter = () => {
    gridApi?.paginationGoToFirstPage();
    setLoadedFilter('');
    filterTable('');
  };

  const QuickFilterValidator = useCallback(
    (term?: string | undefined): ValidationTextResult => {
      const MAX_CHAR = 128;
      const validationResult: ValidationTextResult = {
        status: ValidationStatusEnum.Success,
        message: undefined,
        validatedText: term,
      };
      const scriptTagRegex = new RegExp(/<script[\s\S]*?>[\s\S]*?<\/script>/gi);
      if (term && scriptTagRegex.test(term))
        return {
          status: ValidationStatusEnum.Error,
          message: getTranslation(INPUT_CONTAINS_CODE_ERROR_MESSAGE),
          validatedText: term,
        };
      if (term && term.length > MAX_CHAR)
        return {
          status: ValidationStatusEnum.Error,
          message: getTranslation(INPUT_TOO_LONG_ERROR_MESSAGE),
          validatedText: term.substring(0, MAX_CHAR),
        };
      return validationResult;
    },
    [getTranslation]
  );

  const formatPillText = (text: string) => text.replace('/', ' - ');
  // @ts-ignore
  // @ts-ignore
  const headerPanelElement = (
    <>
      <HeaderPanelContainer id={'header-panel'}>
        <PanelGroup align="start">
          <QuickFilter
            placeholder={capitalize(getTranslation(SEARCH_AUDIENCES))}
            debounceTimeInMilliseconds={DEBOUNCE_TIME}
            filterColId={TEXT_SEARCH_VIRT_COL_ID}
            validate={QuickFilterValidator}
          />
          {props.useCategoryDropdownFilter &&
            props.allowedFilterCategories?.length && (
              <FilterDropdown
                getSelectedOption={filterTable}
                allowedFilterCategories={props.allowedFilterCategories}
                removeAllFilter={props.removeAllFilter}
              />
            )}

          {props.useCategoryDropdownFilter &&
            !!props.highLevelFilters?.length &&
            !props.allowedFilterCategories?.length && (
              <FilterTree
                highLevelFilters={props.highLevelFilters}
                audienceDataClient={props.audienceDataClient}
                filterTable={filterTable}
                setLoadedFilter={setLoadedFilter}
                loadedFilter={loadedFilter}
                currentlyLoading={currentlyLoading}
              />
            )}
        </PanelGroup>
      </HeaderPanelContainer>
      {loadedFilter && (
        <FilterPillContainer id={'filter-panel'}>
          <Pill label={formatPillText(loadedFilter)} onClose={clearFilter} />
        </FilterPillContainer>
      )}
    </>
  );
  const FooterWithPagination = () => (
    <FooterContainer id="footer-panel">
      <PanelGroup align="end">
        <Pagination
          currentlyLoading={currentlyLoading}
          setPageNumber={newPageNumber => setPageNumber(newPageNumber)}
          setPageSize={newPageSize => setPageSize(newPageSize)}
        />
      </PanelGroup>
    </FooterContainer>
  );

  return (
    <ThemeProvider>
      <div data-testid={'data-table-component'} ref={ref}>
        <UnifiedDataTable
          onGridReady={onGridReady}
          uidPrefix="audience-table"
          gridOptions={gridOptions}
          headerPanel={headerPanelElement}
          footerPanel={<FooterWithPagination />}
          noRowsOverlayComponent="noRowsOverlay"
          tableHeight={tableHeight}
          maxTableHeight="none"
          locale={props.locale ?? 'en-US'}
          noRowsOverlayComponentParams={{ succeeded }}
        />
      </div>
    </ThemeProvider>
  );
};

export default TableComponent;
